$(document).ready(function() {
	
	// Validate the form with jquery-validation.
	$("#loginForm").validate({
		rules: {
			email: {
				validEmailAddress: true,
				notNullOrEmpty: true
			},
			password: {
				notNullOrEmpty: true
			}
		},
		messages: {
			email: "A valid email address must be provided",
			password: "A password must be provided"
		},
		highlight: function(element) {
			$(element).addClass("is-danger");
		},
		unhighlight: function(element) {
			$(element).removeClass("is-danger");
		},
		errorPlacement: function(error, element) {
			error.insertAfter(element);
		}
	});
	
	// When the email field receives input, trim it so that it doesn't contain any trailing or leading whitespace.
	$("#email").on("input", function() {
		$(this).val($(this).val().trim());
	});
	
	// When the password field receives input, trim it so that it doesn't contain any trailing or leading whitespace.
	$("#password").on("input", function() {
		$(this).val($(this).val().trim());
	});
	
	// When the mouse is over the zen desk login button, update the action of the form to include a query parameter
	// indicating that the intended redirect after login is to the zen desk site. Set it back to the original action
	// after the mouse leaves the button also.
	$(document).on("mouseover", "#zen-desk-login-button", function() {
		$("#loginForm").attr("action", "/sign-in?zendesk=true");
	});
	$(document).on("mouseleave", "#zen-desk-login-button", function() {
		$("#loginForm").attr("action", "/sign-in?zendesk=false");
	});
	
});